import { defineStore } from "pinia";
import type { UseFetchOptions } from "@vueuse/core";
import useRefreshToken from "~/composables/useRefreshToken";

const { BASE_URL } = useRuntimeConfig().public;
const auth = useCookie<AuthResponse>("auth");
const { refreshToken } = useRefreshToken();

type ComparisonModes = "brands" | "campaigns" | "platforms" | "dateRanges" | null;

function updateSelection(array: string[], id: string): string[] {
  const index = array.indexOf(id);
  return index !== -1 ? array.filter((_, i) => i !== index) : [...array, id];
}

async function fetchWithAuth(url: string, options: UseFetchOptions) {
  const { data, error } = await useFetch<DropdownSelectionResponse>(url, options);
  if (error.value?.statusCode === 401) {
    await refreshToken();
    return useFetch<DropdownSelectionResponse>(url, options);
  }
  return { data };
}

const sortCallback = (a: Record<string, string>, b: Record<string, string>) => a.name.localeCompare(b.name);

export const useReportsFetchStore = defineStore("reportsFetchStore", {
  state: (): ReportsFetchStoreStateType => ({
    brands: [],
    campaigns: [],
    platforms: [],
    fetchedCampaignsByBrand: [],
    fetchedPlatformsByBrandAndCampaign: [],
    preSelectedGender: "",
    preSelectedBrands: [],
    preSelectedCampaigns: [],
    preSelectedPlatforms: [],
    comparisonMode: null,
    preSelectedFirstDateRange: undefined,
    preSelectedSecondDateRange: undefined,
    loading: {
      brands: true,
      campaigns: true,
      platforms: true,
    },
    comingFromCampaigns: false,
    gender: "",
    dbSize: null,
  }),
  getters: {},
  actions: {
    async setPreSelectedBrand(id: string) {
      if (id === "multibrand") {
        // When selecting multibrand, clear all other brand and campaign selections
        this.preSelectedBrands = ["multibrand"];
        this.preSelectedCampaigns = [];
        this.comparisonMode = null;
      } else {
        // When selecting a new brand while multibrand is selected, clear multibrand and campaigns
        if (this.preSelectedBrands.includes("multibrand")) {
          this.preSelectedBrands = [];
          this.preSelectedCampaigns = [];
        }
        // Update the brand selection
        this.preSelectedBrands = updateSelection(this.preSelectedBrands, id);

        // If no brands are selected or more than one brand is selected, clear the campaigns
        if (this.preSelectedBrands.length !== 1) {
          this.preSelectedCampaigns = [];
          this.comparisonMode = null;
        }
      }
      await this.getCampaignsByBrand(id);
      this.preSelectedPlatforms = [];
      this.updateComparisonMode("brands", this.preSelectedBrands.length);
    },
    deletePreSelectedBrand(id: string) {
      this.preSelectedBrands = updateSelection(this.preSelectedBrands, id);
      if (this.preSelectedCampaigns.length > 0) {
        this.preSelectedCampaigns = [];
      }
      this.updateComparisonMode("brands", this.preSelectedBrands.length);
    },
    setPreSelectedCampaign(id: string) {
      if (this.comparisonMode !== "campaigns" && this.comparisonMode !== null) {
        this.preSelectedCampaigns = this.preSelectedCampaigns.includes(id) ? [] : [id];
      } else {
        this.preSelectedCampaigns = updateSelection(this.preSelectedCampaigns, id);
      }
      this.preSelectedPlatforms = [];
      this.updateComparisonMode("campaigns", this.preSelectedCampaigns.length);
    },
    deletePreSelectedCampaign(id: string) {
      this.preSelectedCampaigns = updateSelection(this.preSelectedCampaigns, id);
      this.updateComparisonMode("campaigns", this.preSelectedCampaigns.length);
    },
    deletePreSelectedGender() {
      this.preSelectedGender = "";
    },
    resetPreSelectedCampaigns() {
      this.preSelectedCampaigns = [];
      if (this.comparisonMode === "campaigns") {
        this.comparisonMode = null;
      }
      this.preSelectedPlatforms = [];
      this.preSelectedFirstDateRange = undefined;
      this.preSelectedSecondDateRange = undefined;
    },
    setPreSelectedPlatform(id: string) {
      if (this.comparisonMode !== "platforms" && this.comparisonMode !== null) {
        this.preSelectedPlatforms = this.preSelectedPlatforms.includes(id) ? [] : [id];
      } else {
        this.preSelectedPlatforms = updateSelection(this.preSelectedPlatforms, id);
      }
      this.updateComparisonMode("platforms", this.preSelectedPlatforms.length);
    },
    deletePreSelectedPlatform(id: string) {
      this.preSelectedPlatforms = updateSelection(this.preSelectedPlatforms, id);
      this.updateComparisonMode("platforms", this.preSelectedPlatforms.length);
    },
    resetPreSelectedPlatforms() {
      this.preSelectedPlatforms = [];
      if (this.comparisonMode === "platforms") {
        this.comparisonMode = null;
      }
      this.preSelectedFirstDateRange = undefined;
      this.preSelectedSecondDateRange = undefined;
    },
    deletePreSelectedFirstDateRange() {
      this.preSelectedFirstDateRange = undefined;
    },
    deletePreSelectedSecondDateRange() {
      this.preSelectedSecondDateRange = undefined;
    },
    setPreSelectedGender(gender: string) {
      this.preSelectedGender = gender;
    },
    resetPreSelectedDateRanges() {
      this.preSelectedFirstDateRange = undefined;
      this.preSelectedSecondDateRange = undefined;
      if (this.comparisonMode === "dateRanges") {
        this.comparisonMode = null;
      }
    },
    updateComparisonMode(type: ComparisonModes, count: number) {
      if (count === 2) {
        this.comparisonMode = type;
      } else if (this.comparisonMode === type && count !== 2) {
        this.comparisonMode = null;
      }
    },
    resetAllPreSelected() {
      this.preSelectedBrands = [];
      this.preSelectedCampaigns = [];
      this.preSelectedPlatforms = [];
      this.preSelectedFirstDateRange = undefined;
      this.preSelectedSecondDateRange = undefined;
      this.comparisonMode = null;
    },
    async getCampaignsByBrand(brandId: string) {
      if (!this.fetchedCampaignsByBrand.includes(brandId)) {
        this.loading.campaigns = true;
        const bodyOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth.value.access}`,
          },
          body: {
            brands: [brandId],
            multibrand: this.preSelectedBrands.includes("multibrand"),
            reports: true,
          },
        };
        const { data } = await fetchWithAuth(
          `${BASE_URL}/api/v2/campaigns/retrieve-campaigns/`,
          bodyOptions as UseFetchOptions,
        );
        this.campaigns = [
          ...this.campaigns,
          ...(data.value?.results
            ? Object.entries(data.value.results).map(([id, name]) => ({ brand_id: brandId, id, name }))
            : []),
        ].sort(sortCallback);
        this.fetchedCampaignsByBrand = [...this.fetchedCampaignsByBrand, brandId];
        this.loading.campaigns = false;
      }
    },
    async getPlatformsByBrandsAndCampaigns() {
      const sortedBrandIds = this.preSelectedBrands.slice().sort();
      const sortedCampaignIds = this.preSelectedCampaigns.slice().sort();
      const totalId = [...sortedBrandIds, ...sortedCampaignIds].join("_");
      if (!this.fetchedPlatformsByBrandAndCampaign.includes(totalId)) {
        this.loading.platforms = true;
        const bodyOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth.value.access}`,
          },
          body: {
            brands: this.preSelectedBrands,
            campaigns: this.preSelectedCampaigns,
            reports: true,
          },
        };
        const { data } = await fetchWithAuth(`${BASE_URL}/api/v2/platforms/`, bodyOptions as UseFetchOptions);
        this.platforms = [
          ...this.platforms,
          ...(data.value?.results
            ? Object.entries(data.value.results).map(([id, name]) => ({ selection_id: totalId, id, name }))
            : []),
        ].sort(sortCallback);
        this.fetchedPlatformsByBrandAndCampaign = [...this.fetchedPlatformsByBrandAndCampaign, totalId];
        this.loading.platforms = false;
      }
    },
    async getAllOptions() {
      const bodyOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          reports: true,
        },
      };
      const bodyOptionsSize = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
      };
      this.loading.brands = true;
      this.loading.platforms = true;
      const [brands] = await Promise.all([fetchWithAuth(`${BASE_URL}/api/v2/brands/`, bodyOptions as UseFetchOptions)]);
      this.brands = brands.data.value?.results
        ? [
            ...Object.entries(brands.data.value.results).map(([id, name]) => ({ id, name })),
            { id: "multibrand", name: "Multi-Brand" },
          ].sort(sortCallback)
        : [];
      this.loading.brands = false;
      const [dbSize] = await Promise.all([
        useFetch<DBSize>(`${BASE_URL}/api/v2/audiences/total-database-size/`, bodyOptionsSize as UseFetchOptions),
      ]);
      this.dbSize = dbSize.data.value;
    },
    setComingFromCampaigns(value: boolean) {
      this.comingFromCampaigns = value;
    },
  },
});
